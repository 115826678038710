@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components{
  * {
    font-family: "Titillium Web", sans-serif;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
  }

  .Itemhover:hover{
    background-color: rgb(238, 238, 238);

  }

  .active{
    background-color: rgb(238, 238, 238)
  }

  .grey_color{
    background-color: rgb(238, 238, 238);
  }

  .children{
    background-color: rgb(238, 238, 238);
    width: 100%;
    height: 100%;
    /* height: auto;
    overflow: scroll; */
  }

  .overflow_scroll{
    height: 100vh;
    overflow: scroll;
  }

  .sidebarwidth{
    width: 360px;
  }

  .scroll_inner{
    height: 100%;
    width: 100%;
    overflow: scroll;
  }

  .color_blue{
    background-color: #1915f6;
  }

  .text_blue{
    color: #1915f6;
  }

  .form {
    position: relative;
    width: 50%;
    height: 3rem;
    margin-top: 40px;
  }

  .form3 {
    position: relative;
    width: 50%;
    height: 3rem;
margin: 0 auto;
  }

  .err_msg{
    position: absolute;
    top: 50px;
    font-size: 15px;
    color: red;
  }
  
  .form2 {
    position: relative;
    width: 50%;
    height: 3rem;
    margin-top: 10px;
  }
  
  .form_input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #00054c;
    font-family: inherit;
    font-size: inherit;
    background: none;
    padding: 1.25rem;
  }
  
  .form_input2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #00054c;
    font-family: inherit;
    font-size: inherit;
    background: none;
    padding: 1.25rem;
    resize: none;
    outline: none;
  }
  
  .form_input:hover {
    border-color: #00054c;
  }
  
  .form_input:focus {
    border-color: #00054c;
    border: 1px solid #00054c;
    outline: none;
  }
  
  .form_input2:focus {
    border-color: #00054c;
    border: 1px solid #00054c;
    outline: none;
  }
  
  
  .form_label {
    position: absolute;
    left: 1rem;
    top: 0.8rem;
    padding: 0 0.5rem;
    color: #00054c;
    cursor: pointer;
    background-color:white;
  }
  
  .form_input:focus ~ .form_label,
  .form_input:not(:placeholder-shown).form_input:not(:focus) ~ .form_label {
    top: -0.5rem;
    font-size: 0.8rem;
    left: 0.8rem;
  }
  
  .form_input2:focus ~ .form_label,
  .form_input2:not(:placeholder-shown).form_input2:not(:focus) ~ .form_label {
    top: -0.5rem;
    font-size: 0.8rem;
    left: 0.8rem;
  }
}